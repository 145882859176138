
.modalBody {
    margin-top: 12px;
}

.modalAmount {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 16px;
}

.pill {
    background-color: #EAF6ED;
    width: fit-content;
    margin: auto;
    display: flex;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 100px;
    margin-top: 16px;
    align-items: center;
    justify-content: space-between;
}

.pillIcon {
    width: 24px;
    height: 24px;
}

.pillIcon img {
    width: 100%;
    height: 100%;
}

.modalItemRow {
    padding: 16px 0;
}

.modalItemRow p:nth-child(2) {
    font-size: 18px;
}


/* media queries  */
@media screen and (max-width: 550px) {
    .modalAmount {
        font-size: 28px;
        margin-bottom: 5px;
    }

    .modalItemRow {
        padding: 12px;
    }

    .modalItemRow p:nth-child(1) {
        font-size: 14px;
    }

    .modalItemRow p:nth-child(2) {
        font-size: 16px;
    }

    .pill {
        font-size: 14px;
    }

    .pillIcon {
        width: 20px;
        height: 20px;
    }
}