
.walletContent {
    padding: 35px;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.walletButton {
    background-color: #232A44;
    color: #FFF;
    border: none;
    padding: 14px 16px;
    font-size: 14px;
    font-weight: 500;
}

.walletButton:disabled {
    background-color: #E8E8E8;
    color: #868686;
}

.historyContainer {
    margin-top: 32px;
}

.historyContent {
    margin-top: 24px;
}

.historyRow {
    padding: 16px 0;
    cursor: pointer;
}

.historyRow:hover {
    background-color: rgba(243, 243, 243, 0.3);
}

.historyRow .icon {
    width: 48px;
    height: 48px;
    padding: 14px 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.historyRow .title {
    font-size: 17px;
    font-weight: 500;
}

.historyRow .date {
    font-size: 14px;
    font-weight: 500;
    color: #727272;
}

.historyRow .amount {
    font-size: 17px;
}

.historyRow .icon img {
    width: 24px;
    height: 24px;
}

.iconBg-success {
    background-color: rgba(6, 193, 103, 0.3);
}

.iconBg-danger {
    background-color: rgba(255, 225, 222, 0.3);
}

.iconBg-secondary {
    background-color: var(--primary);
}

.cardLinkBtn {
    padding: 14px 16px;
    border: 2px solid #E8E8E8;
    border-radius: 8px;
    width: 100%;
    margin-top: 15px;
    font-weight: 500;
}

.notif-title {
    font-size: 14px;
    margin-bottom: 5px;
}


@media (max-width: 480px) {
    .cardContainer .balanceCard {
      width: 100%;
    }

    .walletContent {
        padding: 35px 25px;
    }

    .notif-title {
        font-size: 14px !important;
    }

    .notif-icon {
        width: 32px !important;
        height: 32px !important;
    }

    .notif-icon img {
        width: 18px !important;
        height: 18px !important;
    }
}
