.authMainContainer {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.authMainContainer h1,
.authMainContainer h2,
.authMainContainer h3,
.authMainContainer h4,
.authMainContainer h5,
.authMainContainer h6 {
  font-family: "AR One Sans", sans-serif;
}
.authMainContainer p,
.authMainContainer span,
.authMainContainer button {
  font-family: "Satoshi", sans-serif;
}

.authContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.authContainer header {
  text-align: center;
}
.googleAuthBtn {
  border-radius: 8px;
  gap: 10px;
  border: 2px solid #e8e8e8;
  background: inherit;
  padding: 16px;
  width: 100%;
  margin: 32px auto 24px auto;
}
.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.authBtn {
  background: #ffd600;
  color: #000;
  width: 100%;
  border: none;
  font-size: 16px;
  margin-top: 10px;
}
.verifyHeaderText {
  width: 100%;
  margin: 24px 0;
}
.forgotBtn {
  background: inherit;
  border: none;
  align-self: flex-end;
  margin: 0 0 30px 0;
}
.authProfileTab {
  border-radius: 16px;
  border: 2px solid #f3f3f3;
  background: #fff;
  padding: 24px 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.authProfileInitials {
  background: rgba(35, 42, 68, 1);
  height: 80px;
  width: 80px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.authProfileTabStatus {
  border-radius: 99px;
  height: 24px;
  width: 24px;
  border: 2px solid rgba(243, 243, 243, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .authContainer {
    max-width: 796px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
  }
  .googleAuthBtn {
    width: 526px;
  }

  .formContainer {
    width: 526px;
  }
  .verifyHeaderText {
    width: 512px;
  }
}
