body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul,
li,
a {
  list-style: none;
  text-decoration: none;
}

.progress-step.completed .step-icon {
  background-color: #000 !important;
}
.progress-step.completed .step-icon:hover {
  background-color: #000 !important;
}
.progress-step.current .step-index {
  background-color: #000 !important;
}
.progress-step.current .step-index:hover {
  background-color: #000 !important;
}

.progress-step.has-error .step-icon {
  color: white;
  background-color: #fff;
}
.step-content {
  padding: 0.2rem;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 30px !important;
  /* bottom: 20px !important; */
  z-index: 20 !important;
}
/* swiper-pagination-bullets-dynamic swiper-pagination-bullets swiper-pagination-horizontal */