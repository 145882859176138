
.overviewEmptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 126px 100px; */
  padding-top: 100px;
  width: 75%;
  margin: auto;
}
.overviewEmptyContainer > p {
  width: 95%;
  margin: 5px 0 24px 0;
}
.overviewEmptyContainer > button {
  display: flex;
  width: 100%;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  border: none;
  color: #fff;
  border-radius: 8px;
  background: #232a44;
}
/* .contactCornerContainer {
  margin-top: 30px;
} */


/* media queries  */
@media screen and (max-width: 763px) {
  .overviewEmptyContainer {
    width: 85%;
  }
}
