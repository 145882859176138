:root {
  --primary: #FFD600;
  --secondary: #232A44;
  --success: #06C167;
  --successDark: #024022;
  --danger: #FC7F79;
  --dangerDark: #DE1135;
  --warning: #FFD600;
  --white: #FFF;
  --lightGrey: #F3F3F3;
  --lightGreyII: #E8E8E8;
  --black: #101010;
  --zI: 1;
  --zII: 2;
  --zIII: 3;
  --zIV: 4;
  --zV: 5;
  --zX: 10;
  --zMax: 11;
}


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "satoshi", sans-serif;
}

button {
  cursor: pointer;
}

/* custom scrollbars */
::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar{
  height: 2px;
}

::-webkit-scrollbar-track, .hScroll::-webkit-scrollbar-track{
  background: #F6F5F2;
}

::-webkit-scrollbar-thumb, .hScroll::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border-radius: 5px;
}


.fullscreen-loader-text {
  font-size: 26px;
}


.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}
.noWrap {
  flex-wrap: nowrap;
}

.justifyBetween {
  justify-content: space-between;
}
.justifyEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}
.justifySelfEnd {
  justify-self: flex-end;
}
.justifySelfCenter {
  justify-self: center;
  /* border: 1px solid red; */
}

.alignCenter {
  align-items: center;
}
.alignEnd {
  align-items: flex-end;
}
.alignSelfCenter {
  align-self: center;
}

.alignSelfEnd {
  align-self: flex-end;
}

.show {
  display: block !important;
}

.gap-1 {
  gap: 10px;
}


.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mt-1 {
  margin-top: 10px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 10px;
}

.my-2 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mt-2 {
  margin-top: 15px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.my4 {
  margin-block: 4px;
}

.mb-2 {
  margin-bottom: 15px;
}

.ms10 {
  margin-left: 10px;
}

.me10 {
  margin-right: 10px;
}

.p0{
  padding: 0 !important;
}

.p-1 {
  padding: 10px;
}

.py16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.px10 {
  padding-left: 10px;
  padding-right: 10px;
}

.text-muted {
  color: #868686;
}
.textMuted {
  color: #868686;
}

.dNone {
  display: none;
}

.w50 {
  width: 50% !important;
}

.w-70 {
  width: 70%;
}

.w100 {
  width: 100%;
}

.list-divider {
  border-bottom: 1px solid #E8E8E8;
}

.list-divider.wallet-container-spacing {
  padding-block: 15px;
}

.list-divider:last-child {
  border-bottom: none;
}

.btn {
  padding: 10px 12px;
  border-radius: 8px;
  border: none;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.btnLg {
  padding: 14px 45px !important;
}

.btnSm {
  padding: 10px 12px !important;
  font-size: 14px !important;
  height: inherit;
}

.btn-dark {
  background-color: var(--secondary);
  color: var(--white);
}

.btn-primary, .btn-light, .btn-secondary, .btn-danger, .btn-ghost-danger {
  background-color: var(--secondary);
  color: var(--white);
  border: none;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s ease-out;
}

.btn-secondary {
  background-color: var(--primary);
  color: var(--black);
}

.btn-light {
  background-color: var(--lightGreyII);
  color: var(--black);
}

.btn-danger {
  background-color: var(--danger);
  color: var(--white);
}

.btn-ghost-danger {
  background-color: transparent;
  color: var(--dangerDark);
}

.btn-ghost-danger:hover {
  background-color: var(--dangerDark);
  color: var(--white);
}

.btn-light:disabled {
  color: #868686;
  cursor: not-allowed;
}

.btn-ghost {
  background-color: transparent;
  border: none;
}

.btn-ghost:hover {
  background-color: var(--lightGrey);
}

.btn-ghost.no-hover:hover {
  background-color: transparent;
}

.bgGrey {
  background-color: var(--lightGrey);
}

.btnDanger {
  background-color: var(--danger);
  color: var(--white);
}


.text-start {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
}

.radius99 {
  border-radius: 99px;
}

.cursor-pointer {
  cursor: pointer;
}

.overlay, .fullScreenloader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  transition: .3s ease-out;
}

.z500 {
  z-index: 500;
}

.overlay.z600 {
  z-index: 600;
}

.glassMorph {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 600;
}

.posRelative {
  position: relative;
}


.status-pill {
  padding: 8px 16px;
  border-radius: 100px;
  cursor: pointer;
}

.pill-invalid {
  background-color: var(--lightGrey);
  color: var(--black);
}

.pill-warning {
  background-color: #FDF2DC;
  color: #F6BC2F;
}

.pill-success {
  background-color: var(--success);
  color: var(--white);
}

.inputContainer label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

.formInput, .react-datepicker-wrapper, .dropdown-button, .currencyInputContainer {
  width: 100%;
  border: 1px solid var(--lightGreyII);
  border-radius: 8px;
  padding: 10px 8px;
}

.formInput:disabled {
  color: #1A1A1A;
}

.dropdown-button {
  border: 1px solid var(--lightGreyII) !important;
}

.dropdown-button.isDisabled {
  background-color: #FAFAFA;
}

.formInput:focus {
  outline-color: #ccc;
  transition: none;
}






.faintDivider {
  margin: 20px 0;
  border: 1px solid var(--lightGreyII);
  border-top: 0;
}

.animateLogo {
  animation: logoRotate .8s ease-in-out;
  animation-iteration-count: infinite;
}

.fullScreenloader {
  background-color: var(--white);
  z-index: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullScreenloader > div {
  text-align: center;
}

/* game status dot */
.game-active, .game-live, .game-complete {
  color: var(--success);
}

.game-pending {
  color: var(--warning);
}

.game-draft, .game-drafts {
  color: var(--secondary);
}

.game-declined {
  color: var(--dangerDark);
}


/* tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 16px;
}

.tooltip:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  min-width: 200px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  word-wrap: break-word;
  z-index: 1;
}

/* Optional: Style for tooltip arrow */
.tooltip:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}





/* media queries  */
@media screen and (max-width: 768px) {
  .d-none-md {
    display: none;
  }

  .flexRow .inputContainer {
    width: 100% !important;
  }

  .fullscreen-loader-text {
    font-size: 20px;
  }
}

@keyframes slideInRight {
  from {
    left: -50px;
    opacity: 0.8;
  }

  to {
    left: 0;
    opacity: 1;
  }

}

@keyframes slideUp {
  from {
    transform: translate(50%, -40%);
    opacity: 0;
  }

  to {
    transform: translate(50%, -50%);
    opacity: 1;
  }
}

@keyframes logoRotate {
  from {
    transform: rotate(10deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes notificationSlideUp {
  from {
    /* opacity: 0.7; */
    transform: translateY(10px);
  }

  to {
      /* opacity: 1; */
      transform: translateY(0);
  }
}