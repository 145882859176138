
.lotteryContainer .headerTitle {
    font-size: 24px;
    font-weight: 700;
}

.tab {
    margin: 12px 0 24px 0;
    overflow-x: auto;
    display: flex;
}

.tab button {
    background-color: inherit;
    color: #bbb;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--lightGreyII);
}

/* .tab {
    margin: 12px 0 24px 0;
    overflow-x: auto;
} */

/* .tab button {
    background-color: inherit;
    float: left;
    color: #bbb;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--lightGreyII);
} */

.tab button > .badge {
    background-color: #bbb;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: 7px;
}

/* .tab button:hover {
    background-color: var(--lightGrey);
} */

.tab button.active {
    color: var(--secondary);
    border-bottom: 2px solid var(--secondary);
}

.tab button.active > .badge {
    background-color: var(--secondary);
}
 

/* jackpot box & lottery grid */
.lotteryGrid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
}

.lotterySlide {
    display: flex;
    gap: 32px;
    overflow-x: auto;
    max-width: 100%;
}

.lotterySlide .jackpotBox:last-child {
    margin-right: 50px;
}

.jackpotBox {
    /* width: 164px; */
    min-width: 155px;
    cursor: pointer;
}

/* .jackpotBox:hover .box {
    transform: translateY(-3px);
} */

.jackpotBox > .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--lightGreyII);
    padding: 16px;
    height: 164px;
    border-radius: 16px;
    color: var(--white);
    margin-bottom: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    transition: .3s ease-out;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.innerBoxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
}

.ellipseBtn {
    position: relative;
    z-index: 2;
    /* padding: 0 10px !important; */
}

.jackpotBox .timer {
    background-color: #E9E9EC;
    color: #000;
    padding: 4px 8px;
    border-radius: 100px;
    font-size: 12px;
    font-weight: 500;
    align-self: center;
}

.jackpotBox .title {
    font-size: 20px;
    font-weight: 700;
}

.jackpotBox .subtitle {
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.jackpotBox .subtitle > p {
    font-weight: 500;
    color: #48494D;
}

.jackpotBox .subtitle > span {
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
    background-color: #FFF7CC;
    border-radius: 100px;
}

.jackpotBox .ellipse {
    font-size: 24px;
}

.jackpotBox .verified {
    font-size: 32px;
}

.jackpotBox .userGroup {
    font-size: 18px;
}


/**********************************
* Add lottery
***********************************/
.addLotteryHeader {
    margin-bottom: 16px;
}

.addLotteryHeader h2 {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 5px;
}

.addLottery .avatar {
    background-color: var(--lightGreyII);
    margin-right: 27px;
    overflow: hidden;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.addLottery .avatar > .cameraImg {
    width: 60px;
    height: 60px;
}

.addLottery .avatar > .fileImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.addLotteryHeader p {
    font-size: 14px;
}


.lotteryForm {
    margin-top: 30px;
}

.lotteryForm > .col3 .inputContainer {
    width: 30%;
}

.lotteryForm > .col2 .inputContainer {
    width: 48%;
}

.lotteryForm > .flexRow {
    flex-wrap: wrap;
    gap: 15px;
    row-gap: 0;
}


.formDesc {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--lightGreyII);
    margin-bottom: 16px;
}

.packageImg > img {
    width: 160px;
    height: 160px;
}

.modalText > p {
    margin-top: 8px;
    margin-bottom: 40px;
}

.formButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
}

.formButtonContainer.col3 {
    justify-content: space-between;
}

.formButtonContainer.col3 .btnGroup:nth-child(2) {
    display: flex;
    gap: 10px;
}

.lotterySteps {
    position: relative;
    margin-top: 8px;
}

.lotterySteps .step {
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 14px;
    padding-left: 0;
}

.step .num {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #E1E2E5;
    color: #787A80;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.step.active .num {
    background-color: var(--secondary);
    color: var(--white);
}

.step::after {
    content: '';
    background-color: #E1E2E5;
    width: 2px;
    height: 70px;
    position: absolute;
    top: 36px;
    left: 17px;
    z-index: 0;
}

.asideViewContainer {
    padding-inline: 20px !important;
}

.step.active::after {
    background-color: var(--secondary);
}

.viewGameAvatarContainer {
    background-color: #F7F8FA;
    border-radius: 16px;
    padding: 16px;
    border-bottom: none !important;
    margin-bottom: 24px;
}

.viewGameAvatarContainer .subtitle {
    margin-block: 8px;
}

.viewGameAvatarContainer > .avatar {
    margin-right: 8px;
}

.liveUserStatFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.timerLg {
    background-color: #E9E9EC;
    padding: 8px 16px;
    border-radius: 100px;
}

.asideCardContainer {
    margin-top: 48px;
}

.ticketMessageFlex {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-block: 24px;
}

.ticketMessageFlex .ticket, .ticketMessageFlex .message {
    width: 50%;
}

.ticket .box, .message .box {
    box-sizing: border-box;
    width: 100%;
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 16px 0px;
    padding: 15px;
    height: 216px;
    overflow-y: auto;
    margin-top: 16px;
    border: 1px solid #E1E2E5;
}

.box .ticketContainer {
    display: block;
    white-space: nowrap;
    overflow: auto hidden;
    max-width: 100%;
}

.ticketContainer table {
    font-variant-numeric: lining-nums tabular-nums;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
}

.ticket table thead td {
    text-align: start;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    line-height: 1.25rem;
    font-size: 12px;
    font-weight: 500;
    color: rgb(120, 122, 128);
    border-color: rgba(255, 255, 255, 0.06);
    padding: 0px 8px 8px;
}

.st1 {
    text-align: start;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    line-height: 1.25rem;
    font-weight: 500;
    border-color: rgba(255, 255, 255, 0.06);
    padding: 8px;
}

.st2 {
    text-align: start;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    line-height: 1.25rem;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    border-color: rgba(255, 255, 255, 0.06);
    padding: 8px;
}

.st3 {
    text-align: start;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    line-height: 1.25rem;
    color: rgb(72, 73, 77);
    border-color: rgba(255, 255, 255, 0.06);
    padding: 8px;
}

.st1.mid {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background: rgb(247, 248, 250);
}

.st2.mid {
    background: rgb(247, 248, 250);

}

.st3.mid {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: rgb(247, 248, 250);
}

.tablePill {
    text-align: center;
    width: fit-content;
    background: rgb(255, 239, 153);
    border-radius: 50px;
    padding: 4px 8px;
}

.msgIcon {
    height: 24px;
    width: 24px;
}

.asideTitle {
    font-size: 14px;
    font-weight: 500;
}

.mb12 {
    margin-bottom: 12px;
}

.ticketSalesModal {
    width: 700px !important;
}

.leaderboardContainer {
    padding: 14px;
    padding-block: 20px;
    background-color: #1A1A1A;
    color: #fff;
    border-radius: 16px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.06);
}

.tableResponsive {
    display: block;
    white-space: nowrap;
    overflow: auto hidden;
    max-width: 100%;
}

.leaderboardContainer table {
    font-variant-numeric: lining-nums tabular-nums;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
}

.leaderboardContainer thead td {
    font-size: 12px;
    font-weight: 500;
    padding: 0 8px 16px;
    color: #787A80;
}

.leaderboardContainer tbody {
    margin-top: 16px;
}

.leaderboardContainer tbody tr {
    color: #FCFDFF;
}

.leaderboardContainer tbody td {
    padding: 8px;
}

.leaderboardContainer tbody tr td:nth-child(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.leaderboardContainer tbody tr td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.activeUser {
    background-color: rgba(39, 39, 39, 1);
    animation: activePlayerAnime 2s linear infinite;
}

.tableAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    padding: 14px 15px;
    border-radius: 50%;
    background-color: var(--primary);
    font-size: 20px;
    font-weight: 700;
    color: #101010;
}

.leaderName {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
}

.leaderLottoNumber {
    background-color: #FFEF99;
    border-radius: 50px;
    text-align: center;
    width: fit-content;
    padding: 4px 8px;
    color: #101010;
}

.ticketRow {
    border-bottom: 1px solid #E8E8E8;
    padding-block: 10px;
    padding-inline: 2px;
    cursor: pointer;
}

.ticketRow:hover {
    background-color: #fafafa;
}

.ticketRow:last-child {
    border-bottom: none;
}

.modalTextResp {
    font-size: 14px;
}

.ticketIdPill {
    font-size: 14px;
    background-color: var(--lightGrey);
    color: #727272;
    border-radius: 100px;
    cursor: pointer;
    padding: 10px 12px;
    font-size: 14px;
    height: inherit;
}

.ticketSaleAvatar {
    width: 48px !important;
    height: 48px !important;
}

.ticketSaleAvatar > span {
    font-size: 20px !important;
}

.optionsContainer {
    background-color: #fff;
    border-radius: 12px;
    position: absolute;
    top: 10px;
    right: -120px;
    min-width: 180px;
    box-shadow: 0px 4px 16px 0px #0000001F;
    overflow: hidden;
    z-index: 3;
    animation: fadeInSlideUp 0.3s ease-out;
}

.optionsContainer a, .delOpt {
    color: #1A1A1A;
    font-size: 14px;
    font-weight: 500;
    display: block;
    padding: 16px;
}

.optionsContainer svg {
    font-size: 20px;
    margin-right: 16px;
}

.optionsContainer a:hover, .delOpt:hover {
    background-color: #f7f7f7;
}

.optionsOverlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.emptyGamesText {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100px;
}

.modalStep2 {
    animation: slideInLeft .5s ease-out;
}

.ticketInfoContainer {
    border: 1px solid #E8E8E8;
    padding: 16px;
    border-radius: 8px;
    margin-top: 24px;
}


.modalItemRow {
    padding: 16px 0;
}

.modalItemRow p:nth-child(2) {
    font-size: 18px;
}


.luckyNumContainer {
    border: 2px solid #E1E2E5;
    padding: 12px 16px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    width: fit-content;
    margin-top: 8px;
}

.luckyNum {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--primary);
    color: #101010;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1425px) {
    .lotteryGrid {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
    }
}

@media screen and (max-width: 850px) {

    .mainContainer {
        display: block !important;
    }

    .asideCardContainer {
        margin-top: 0;
    }
}

@media screen and (max-width: 763px) {

    .lotteryForm .col3, .lotteryForm .col2 {
        flex-direction: column;
    }

    /* add lottery */
    .formButtonContainer {
        justify-content: center;
    }

    .formButtonContainer.addGame > button {
        width: 50%;
    }

    .formButtonContainer.col3 {
        align-items: center;
        flex-direction: column-reverse;
    }

    .formButtonContainer.col3 .btnGroup {
        width: 100%;
    }

    .formButtonContainer button {
        width: 50%;
        padding-inline: 12px !important;
    }

    .formButtonContainer.col3 .backBtn {
        width: 100%;
        text-align: center;
        background-color: transparent;
        border: none;
    }

    .formButtonContainer.col3 .backBtn > span.flexRow {
        justify-content: center;
    }

    .formButtonContainer.col3 {
        flex-direction: column-reverse;
    }

    .avatarProfileContainer {
        flex-direction: column !important;
        gap: 15px;
        min-height: 100px;
    }

    .avatarProfileContainer .uploadBtn {
        margin-top: 0;
    }

    .avatarProfileContainer .avatar {
        margin-right: 0;
    }

    .avatarProfileContainer .content {
        text-align: center;
    }

    .avatarProfileContainer .formDates {
        margin-top: 8px;
    }

    .formButtonContainer {
        margin-top: 20px;
    }

    .ticketSalesModal {
        width: 90% !important;
    }
    
}


@media screen and (max-width: 550px) {
    .lotteryGrid {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
    }

    .jackpotBox {
        min-width: 130px;
    }
    
    .lotterySlide {
        gap: 18px;
    }
    
    .modalItemRow {
        padding: 12px 0;
    }

    .modalItemRow p:nth-child(1) {
        font-size: 14px;
    }

    .modalItemRow p:nth-child(2) {
        font-size: 16px;
    }

    .luckyNumContainer {
        padding: 6px 10px;
    }

    .luckyNum {
        width: 28px;
        height: 28px;
        font-size: 14px;
    }    

}


@media screen and (max-width: 500px) {

    .mainContent {
        padding-top: 20px;
        padding-inline: 15px;
    }

    /* jackpot box */
    /* .jackpotBox {
        width: 130px;
    } */

    .jackpotBox > .box {
        height: 130px;
        margin-bottom: 5px;
    }

    .jackpotBox .title {
        font-size: 18px;
    }

    .jackpotBox .subtitle > p {
        font-size: 14px;
    }

    .jackpotBox .timer {
        font-size: 10px;
        padding: 2px 4px;
    }

    .jackpotBox .ellipse {
        font-size: 18px;
    }

    .jackpotBox .verified {
        font-size: 24px;
    }
    
    .jackpotBox .userGroup {
        font-size: 16px;
    }

    .jackpotBox .ticketStat {
        font-size: 14px;
    }
    
    .optionsContainer {
        min-width: 100px;
        right: -15px;
        top: 13px;
        padding-block: 5px;
    }

    .optionsContainer a, .delOpt {
        font-size: 12px;
        padding: 10px;
    }

    .optionsContainer svg {
        font-size: 15px;
        margin-right: 10px;
    }

    .liveUserStatFlex {
        flex-direction: column;
        gap: 8px;
    }

    .ticketMessageFlex {
        flex-direction: column;
    }

    .ticketMessageFlex .ticket, .ticketMessageFlex .message {
        width: 100%;
    }

    .modalTextResp {
        font-size: 10px !important;
    }
    
    .ticketSaleAvatar {
        width: 30px !important;
        height: 30px !important;
    }

    .ticketSaleAvatar > span {
        font-size: 16px !important;
    }

    .statusPill {
        padding: 5px 6px !important;
    }

    .ticketSalesModal {
        padding: 18px !important;
        padding-inline: 14px !important;
    }

}


@keyframes fadeInSlideUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
  
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes activePlayerAnime {
    10% {
        background-color: rgba(50, 50, 50, 0.5);
    }
    
    50% {
        background-color: rgba(50, 50, 50, 1);
    }
    
    100% {
        background-color: rgba(50, 50, 50, 0.5);
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(20px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}