.mainContainer {
  display: grid;
  grid-template-columns: 65% 35%;
  min-height: 100vh;
}

.mainContainer.w75-25 {
  grid-template-columns: 75% 25%;
}

.mainContainer.w65-45 {
  grid-template-columns: 55% 45%;
}

.mainContainer.no-aside {
  grid-template-columns: 1fr;
}

/* side bar  */
.sideBarContainer {
  background: rgba(35, 42, 68, 1);
  padding: 24px 16px;
  height: 100vh;
}

.sideBarContainer ul {
  margin-top: 40px;
}

.sideBarContainer ul li a {
  padding: 10px 16px;
  color: rgba(255, 255, 255, 1);
}
.sideBarActiveLink {
  background: rgba(255, 214, 0, 1);
  color: #000 !important;
  border-radius: 8px;
}

/* top nav */
.topNavContainer {
  padding: 16px 32px;
  background: rgba(255, 255, 255, 1);
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: var(--zX);
}

.topNavSearchContainer {
  background: rgba(243, 243, 243, 1);
  padding: 8px;
  width: 375px;
  display: flex;
  align-items: center;
  border-radius: 99px;
}
.topNavSearchContainer input {
  background: inherit;
  width: 100%;
  border: none;
  outline: none;
}
.topNavSearchContainer input::placeholder {
  color: rgba(114, 114, 114, 1);
  font-size: 14px;
  font-family: "Satoshi", sans-serif;
}

.topNavUserContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nameTagContainer {
  /* set h&w in coponent */
  /* height: 36px;
  width: 36px; */
  border-radius: 99px;
  background: rgba(255, 214, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.nameTagContainer.hasSrc {
  padding: 0 !important;
}

.nameTagContainer .nameTagImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bell-icon {
  cursor: pointer;
  font-size: 22px;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 50%;
}

.bell-icon:hover {
  background-color: #f7f7f7;
}

.notification-dropdown {
  background-color: #fff;
  border-radius: 16px;
  position: fixed;
  top: 60px;
  right: 29px;
  width: 647px;
  max-height: 80vh;
  min-height: 100px;
  overflow-y: auto;
  box-shadow: 0px 4px 16px 0px #0000001f;
  z-index: var(--zMax);
  animation: notificationSlideUp 0.5s ease-out;
  padding: 24px;
}

.notification-dropdown .header-title {
  font-size: 21px;
  font-weight: 700;
}

.notification-dropdown .content {
  margin-top: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 24px;
}

.ticket-notification-row .title {
  font-size: 14px !important;
  font-weight: 500;
}

.ticket-notification-row .status-text,
.ticket-notification-row .date {
  font-size: 11px !important;
}

.notification-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: var(--zX);
}

.msg-box {
  width: 222px;
}

.social-icons {
  font-size: 38px;
}

/********************************************
* dashboard styles 
********************************************/
/* .dashboardLayoutContainer {
  display: flex;
} */

/* .sideBarContainer {
  width: 30%;
  display: block;
} */

.dashboardLayoutSideBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 269px;
}

.dashboardLayoutContentContainer {
  margin-left: 269px;
}

.asideViewContainer {
  background: #f3f3f3;
  padding: 32px;
}

.mainContent {
  padding: 35px;
}

/* media queries  */
@media screen and (max-width: 1150px) {
  .dashboardLayoutSideBar {
    display: none;
    animation: slideInRight 0.3s ease-out;
    z-index: 5;
  }

  .sideBarContainer {
    padding-top: 100px;
  }

  .dashboardLayoutContentContainer {
    margin-left: 0;
  }
}

/* media queries  */
@media screen and (max-width: 850px) {
  .mainContainer {
    display: block;
  }

  /* .mainContainer, .mainContainer.w75-25, .mainContainer.w65-45 {
    grid-template-columns: 1fr;
  } */

  .asideViewContainer.hideOnMobile {
    display: none;
  }

  .asideViewContainer {
    padding-inline: 22px;
  }

  .topNavSearchContainer {
    width: calc(100% - 63px);
  }

  .topNavContainer {
    padding: 16px 20px;
  }

  .notification-dropdown {
    width: 90%;
    padding: 18px;
    right: 10px;
  }

  .notification-dropdown .content {
    padding: 18px;
  }

  .notification-dropdown .header-title {
    font-size: 20px;
  }

  .social-icons {
    font-size: 27px;
  }
}

.stat-title {
  font-size: 14px;
}

@media (max-width: 480px) {
  .mainContent {
    padding: 35px 25px;
  }

  .msg-box {
    width: 100%;
  }

  .social-icons {
    font-size: 20px;
  }

  .stat-title {
    font-size: 12px;
  }
}
